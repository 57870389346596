import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as API from "../../utils/api.service";
import { ADMIN } from "../../app.config";
import { Helmet } from "react-helmet";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = '/' + location?.pathname?.split('/')?.[1]

  const handleLogout = async () => {
    try {
      await API.logout();
      localStorage.removeItem(ADMIN);
      navigate("/login");
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <>
      <div className="left-side-menu" style={{ "top": "45px" }}>

        {/* <div className="h-100" data-simplebar> */}
          <div className="h-100">
          <div id="sidebar-menu">
            <ul id="side-menu">
              <li className={`${path === "/" ? "actived" : "inactived"}`}>
                <Link to="/" className={`${path === "/" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-cast ${path === "/" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/" ? "actived-font" : "inactived-font"}`}> Dashboard </span>
                </Link>
              </li>

              <li className={`${path === "/user-list" ? "actived" : "inactived"}`}>
                <Link to="/user-list" className={`${path === "/user-list" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-people-fill ${path === "/user-list" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/user-list" ? "actived-font" : "inactived-font"}`}> Students </span>

                  {/* <span className="badge bg-danger float-end" data-plugin="counterup" style={{ "fontSize": "13px", "marginTop": "2px" }}>{customerListing}</span> */}
                </Link>
              </li>

              <li className={`${path === "/teacher-list" ? "actived" : "inactived"}`}>
                <Link to="/teacher-list" className={`${path === "/teacher-list" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-person ${path === "/teacher-list" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/teacher-list" ? "actived-font" : "inactived-font"}`}> Teachers </span>

                  {/* <span className="badge bg-danger float-end" data-plugin="counterup" style={{ "fontSize": "13px", "marginTop": "2px" }}>{customerListing}</span> */}
                </Link>
              </li>

              {/* <li className={`${path === "/notification-list" ? "actived" : "inactived"}`}>
                <Link to="/notification-list" className={`${path === "/notification-list" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-bell ${path === "/notification-list" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/notification-list" ? "actived-font" : "inactived-font"}`}> Notification </span>
                </Link>
              </li>

              <li className={`${path === "/contact-us" ? "actived" : "inactived"}`}>
                <Link to="/contact-us" className={`${path === "/contact-us" ? "actived" : "inactived"}`}>
                  <i className={`${path === "/contact-us" ? "actived-font bi bi-telephone" : "inactived-font bi bi-telephone"}`}></i>
                  <span className={`${path === "/contact-us" ? "actived-font" : "inactived-font"}`}> Contact Us </span>
                </Link>
              </li> */}

              <li className={`${path === "/setting" ? "actived" : "inactived"}`}>
                <Link to="/setting" className={`${path === "/setting" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-gear-fill ${path === "/setting" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/setting" ? "actived-font" : "inactived-font"}`}> Settings </span>
                </Link>
              </li>

              <li className={`${path === "/subject-list" ? "actived" : "inactived"}`}>
                <Link to="/subject-list" className={`${path === "/subject-list" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-book-half ${path === "/subject-list" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/subject-list" ? "actived-font" : "inactived-font"}`}> Subjects </span>
                </Link>
              </li>

              <li className={`${path === "/language-list" ? "actived" : "inactived"}`}>
                <Link to="/language-list" className={`${path === "/language-list" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-translate ${path === "/language-list" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/language-list" ? "actived-font" : "inactived-font"}`}> Languages </span>
                </Link>
              </li>
              {/* <li>
                <a href="#appcontent" data-bs-toggle="collapse" className={`${path === "/about-us" ? "actived" : path === "/privacy-policy" ? "actived" : path === "/terms-condition" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-list-task ${path === "/about-us" ? "actived-font" : path === "/privacy-policy" ? "actived-font" : path === "/terms-condition" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/about-us" ? "actived-font" : path === "/privacy-policy" ? "actived-font" : path === "/terms-condition" ? "actived-font" : "inactived-font"}`}> App Content </span>
                  <span className={`menu-arrow ${path === "/about-us" ? "actived-font" : path === "/privacy-policy" ? "actived-font" : path === "/terms-condition" ? "actived-font" : "inactived-font"}`}></span>
                </a>
                <div className="collapse" id="appcontent">
                  <ul className="nav-second-level">

                    <li className={`${path === "/about-us" ? "actived" : "inactived"}`}>
                      <Link to="/about-us" className={`${path === "/about-us" ? "actived-font" : "inactived-font"}`} style={{ "margin": "2px" }}><i className={`bi bi-arrow-right-short ${path === "/about-us" ? "actived-font" : "inactived-font"}`}></i> About Us</Link>
                    </li>

                    <li className={`${path === "/privacy-policy" ? "actived" : "inactived"}`}>
                      <Link to="/privacy-policy" className={`${path === "/privacy-policy" ? "actived-font" : "inactived-font"}`} style={{ "margin": "2px" }}><i className={`bi bi-arrow-right-short ${path === "/privacy-policy" ? "actived-font" : "inactived-font"}`}></i> Privacy Policy</Link>
                    </li>

                    <li className={`${path === "/terms-condition" ? "actived" : "inactived"}`}>
                      <Link to="/terms-condition" className={`${path === "/terms-condition" ? "actived-font" : "inactived-font"}`} style={{ "margin": "2px" }}><i className={`bi bi-arrow-right-short ${path === "/terms-condition" ? "actived-font" : "inactived-font"}`}></i> Terms & Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className={`${path === "/faq" ? "actived" : "inactived"}`}>
                <Link to="/faq" className={`${path === "/faq" ? "actived" : "inactived"}`}>
                  <i className={`bi bi-question-circle-fill ${path === "/faq" ? "actived-font" : "inactived-font"}`}></i>
                  <span className={`${path === "/faq" ? "actived-font" : "inactived-font"}`}> FAQ </span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
