import { Suspense, lazy, useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Routing
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layout/DashboardLayout";

export const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

const Spinner = Loadable(lazy(() => import("../Pages/Common/Spinner")));
const Login = Loadable(lazy(() => import("./../Auth/Login")));
const LockScreen = Loadable(lazy(() => import("./../Auth/LockScreen")));
const ForgotPassword = Loadable(lazy(() => import("./../Auth/ForgotPassword")));
const Dashboard = Loadable(lazy(() => import("./../Pages/Dashboard")));
const Settings = Loadable(lazy(() => import("./../Pages/Settings")));
const UserList = Loadable(lazy(() => import("../Pages/UserDetailed")));
const ViewUserDetailes = Loadable(lazy(() => import("../Pages/UserDetailed/ViewUserDetailed")));
const EditUsersDetailes = Loadable(lazy(() => import("../Pages/UserDetailed/EditUser")));
const Notification = Loadable(lazy(() => import("../Pages/Notification")));
const AddNotification = Loadable(lazy(() => import("../Pages/Notification/AddNotification")));
const ContactUs = Loadable(lazy(() => import("../Pages/ContactUs")));
const AboutUs = Loadable(lazy(() => import("../Pages/AppContent")));
const FAQ = Loadable(lazy(() => import("../Pages/FAQS")));
const AddFAQ = Loadable(lazy(() => import("../Pages/FAQS/AddFAQ")));
const TeacherList = Loadable(lazy(() => import("../Pages/ManageTeachers")));
const TeacherDetails = Loadable(lazy(() => import("../Pages/ManageTeachers/ViewTeacherDetailed")));
const EditTeacherDetails = Loadable(lazy(() => import("../Pages/ManageTeachers/EditTeacher")));
const LanguageList = Loadable(lazy(() => import("../Pages/Language/index")));
const AddLanguage = Loadable(lazy(() => import("../Pages/Language/addLanguage")));
const SubjectList = Loadable(lazy(() => import("../Pages/Subject/index")));
const AddSubject = Loadable(lazy(() => import("../Pages/Subject/addSubject")));

const Router = () => {
 
  const location = useLocation();
  const currentPath = location.pathname.split("/");
  let { isLoading } = useSelector((state) => state.master)

  if (!localStorage.getItem("AisLogin", false)) {
    return (
      <>
        <Spinner message="Please Wait..." isActive={isLoading} />
        <Routes>
          <Route>
            <Route path='/login' element={<Login />} />
            <Route path='/lock-screen' element={<LockScreen />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='*' element={<Login />} />
          </Route>
        </Routes>
      </>
    );
  } else {
    return (
      <>
      <Spinner message="Please Wait..." isActive={isLoading} />
        <Routes>
          <Route element={<DashboardLayout/>}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/setting' element={<Settings />} />
            {/* Usre Route */}
            <Route path='/user-list' element={<UserList />} />
            <Route path='/user-list/view' element={<ViewUserDetailes />} />
            <Route path='/user-list/edit' element={<EditUsersDetailes />} />
            <Route path='/notification-list' element={<Notification />} />
            <Route path='/notification-list/add-notification' element={<AddNotification />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/privacy-policy' element={<AboutUs />} />
            <Route path='/terms-condition' element={<AboutUs />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/faq/add-faq' element={<AddFAQ />} />

            {/* Teacher Route */}
            <Route path='/teacher-list' element={<TeacherList />} />
            <Route path='/teacher-list/view' element={<TeacherDetails />} />
            <Route path='/teacher-list/edit' element={<EditTeacherDetails />} />
            <Route path='/language-list' element={<LanguageList />} />
            <Route path='/language-list/add-language' element={<AddLanguage />} />
            <Route path='/subject-list' element={<SubjectList />} />
            <Route path='/subject-list/add-subject' element={<AddSubject />} />
            <Route path='*' element={<Dashboard />} />
          </Route>
        </Routes>
      </>
    );
  }
};

export default Router;
