import { toast } from "react-toastify";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";
import { SWEET_ALERT_CANCEL_COLOR, SWEET_ALERT_OK_COLOR } from "../app.config";

const capitalizeWords = (str) => {
  const hyphenIndex = str.indexOf("-");
  if (hyphenIndex !== -1) {
    const words = str.split("-");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(" ");
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

const formatArrayToString = (array) => {
  let temp = "";
  for (let item of array) {
    if (temp) temp = `${temp}, ${item?.label || item}`;
    else temp = item?.label || item;
  }
  return temp;
};

const validateLessThanMax = (min, max, errMsg) => {
  return min < max || errMsg;
};

const isActive = (status) => {
  return status ? "Active" : "Inactive";
};

const formatDate = (date, format) => {
  return dayjs(date).format(format);
};

const formatDateLocalToUtc = (date, format) => {
  const localTime = dayjs(date);
  const localOffset = localTime.utcOffset();
  return localTime.subtract(localOffset, 'minute').format(format);
};

const convert12hrTo24hr = (time) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, "h:mm A").format("HH:mm");
};

const convert24hrTo12hr = (time) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, "HH:mm").format("h:mm A");
};

const convertToBase64 = async (file) => {
  if (file.type.includes("video")) return URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    file && fileReader.readAsDataURL(file);
  });
};

const SWIT_SUCCESS = (message) => {
  return Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: message,
    showConfirmButton: false,
    timer: 1800
  })
};

const SWIT_DELETE = (message) => {
  return Swal.fire({
    title: 'Are you sure?',
    text: message?message:"You won't be able to delete this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: SWEET_ALERT_CANCEL_COLOR,
    cancelButtonColor: SWEET_ALERT_OK_COLOR,
    confirmButtonText: 'Yes, delete it!'
  })
};

const SWIT_DELETE_SUCCESS = (message) => {
  return Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: message?message:'Your data has been deleted.',
    showConfirmButton: false,
    timer: 1500
})
};

const SWIT_PASSWORD_CHANGE = (message) => {
  return Swal.fire({
    title: 'Are you sure you want to change your password?',
    text: message ? message : "You won't be able to change this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: SWEET_ALERT_CANCEL_COLOR,
    cancelButtonColor: SWEET_ALERT_OK_COLOR,
    confirmButtonText: 'Yes, change it!'
  })
};

const SWIT_PASS_CHANGE_SUCCESS = (message) => {
  return Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: message ? message : 'Your password has been changed successfully.',
    showConfirmButton: false,
    timer: 1500
  })
};

const TOAST_SUCCESS = (message) => {
  return toast.success(message);
};

const TOAST_INFO = (message) => {
  return toast.info(message);
};

const TOAST_ERROR = (message) => {
  return toast.error(message);
};

const TOAST_WARNING = (message) => {
  return toast.warning(message);
};

export {
  capitalizeWords,
  formatArrayToString,
  validateLessThanMax,
  isActive,
  formatDate,
  formatDateLocalToUtc,
  convert12hrTo24hr,
  convert24hrTo12hr,
  convertToBase64,
  TOAST_SUCCESS,
  SWIT_SUCCESS,
  SWIT_DELETE,
  SWIT_DELETE_SUCCESS,
  SWIT_PASSWORD_CHANGE,
  SWIT_PASS_CHANGE_SUCCESS,
  TOAST_INFO,
  TOAST_ERROR,
  TOAST_WARNING,
};
